body {
  background-color: rgb(178, 47, 175);
  font-family: "Montserrat", sans-serif;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

.font-title {
  font-family: "Permanent Marker", cursive !important;
}
